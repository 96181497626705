.login-page#cover {
    background-size: cover;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .login-page #cover-caption {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  
  /* only used for background overlay not needed for centering */
  .login-page form:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    border-radius: 10px;
    border: 1px solid black;
  }
  